<template>
  <div class="matchManage">
    <div class="search">
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        type="text"
        placeholder="请输入内容"
        v-model="searchValue"
      ></el-input>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
      <el-button type="primary" @click="handleAdd">
        <span>新增</span>
      </el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="比赛ID" width="80">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="比赛名称">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.matchName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开始时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.regBegin }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.matchEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            state[scope.row.matchStatus]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报名用户数">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.userCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作品数量">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.presCount == null ? 0 : scope.row.presCount
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.recommend"
            active-value="YES"
            inactive-value="NO"
            @change="switchChange($event, scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button size="mini" type="info" @click="handleReasult(scope.row)"
            >结果</el-button
          >
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="handleStop(scope.row)"
            >停止</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 结果 排行榜弹窗 -->
    <el-dialog
      title=""
      :visible.sync="dialogTableVisible"
      center
      custom-class="dialogP"
    >
      <div class="rankList">
        <div class="list-top">
          <div>排名</div>
          <div>名称</div>
          <div>评分</div>
          <div>票数</div>
        </div>
        <div class="list-bottom" v-for="(item, index) in rankList" :key="index">
          <div>{{ index + 1 }}</div>
          <div>{{ item.presTitle }}</div>
          <div>{{ item.expertScore }}</div>
          <div>{{ item.tickets }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "比赛ID",
        },
        {
          value: 2,
          label: "比赛名称",
        },
      ],
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      searchValue: "", // 搜索内容
      dialogTableVisible: false,
      textarea: "",
      state: {
        REG_PROGRESS: "进行中",
        PUBLIC_REVIEW: "大众评审",
        EXPERT_REVIEW: "专家评审",
        RESULT_ANNOUNCE: "结果公布",
        END: "已结束",
      },
      nowInfo: {}, // 当前点击参数
      rankList: [], // 排行榜列表
    };
  },
  methods: {
    // 重置
    handleReast() {
      this.value = 1;
      this.searchValue = "";
      this.getList();
    },
    // 查询
    handleSearch() {
      this.getList();
    },
    // 新增
    handleAdd() {
      this.$router.push("/layerPage/addMatch");
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 会员列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
      };
      // 根据value判断当前搜索
      switch (this.value) {
        case 1:
          params.id = this.searchValue;
          break;
        case 2:
          params.matchName = this.searchValue;
          break;
      }
      this.$axiosGet("matchManager/queryPage", params).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 停止比赛
    handleStop(row) {
      this.$axiosPost("matchManager/stopMatch", { id: row.id }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
        this.getList();
      });
    },
    // 查看比赛结果
    handleReasult(row) {
      this.getRank(row.id);
    },
    // 编辑比赛
    handleEdit(row) {
      this.$router.push({
        path: "/layerPage/addMatch",
        query: { id: row.id },
      });
    },
    // 比赛排行榜
    getRank(id) {
      let params = {
        contestId: id,
      };
      this.$axiosGet("matchManager/queryMatchRank", params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.dialogTableVisible = true;
          this.rankList = res.data.records;
        } else {
          this.$message({
            message: "当前无结果公示",
            type: "warning",
          });
        }
      });
    },
    // 是否推荐
    switchChange(e, row) {
      this.$axiosPost("matchManager/changeRecommend", {
        contestId: row.id,
        recommend: e,
      }).then(() => {
        this.getList();
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less">
.matchManage {
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 38px;

    .el-input {
      width: 200px;
      margin: 0 10px;
    }
  }

  .pagination {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
  }

  .el-button {
    padding: 10px 20px;
  }
  .userInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    div {
      width: 80%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btns {
      width: 30%;
    }
  }
}
.dialogP {
  height: 600px;
  overflow-y: scroll;
}
.rankList {
  .list-top,
  .list-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 16px;
    div {
      text-align: center;
      flex: 1;
      font-size: 14px;
    }
  }
  .list-top {
    margin-bottom: 20px;
    div {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
